import 'react-circular-progressbar/dist/styles.css';
import React, { Component } from 'react';
import { ACCESS_TOKEN_STORAGE_KEY } from '../../../config/constants';
import { Button } from 'antd';
import { CircularProgressbar } from 'react-circular-progressbar';
import { TwinData } from '../../../config/types';
import { TwinsTable } from '../../connected';
import { calculateLoadingPercentage } from '../../../utils/modelViewing';
import isEqual from 'lodash/isEqual';
import { notification } from '../../../antdProvider';
import styles from './styles.module.less';

interface Props {
  source: string
  modelCode: string
  objectKey: string
  twinData: TwinData
  config: string
  loadModels: Array<string>
  project: string
  tenant: string,
  isTwinLoading: boolean
  requestModelMapping?: string
  mappingOverride?: { [twinID: string]: Array<number> }
  onTwinReady?: (boolean, coordinates: { x: number, y: number, z: number }) => void
  onTwinLoading?: (number) => void
  onTwinCancel?: () => void
  onMapProgress?: (progress: number) => void
  onMapComplete?: (mapping: { [twinID: string]: Array<number> }) => void
}

interface State {
  showModal: boolean
  assetTwinDataFromForge: Array<string>
  modelByKeys: { [id: string]: { id: string, loading: number } };
}

export default class ModelViewer extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      assetTwinDataFromForge: [],
      showModal: false,
      modelByKeys: {},
    };

    this.viewerRef = React.createRef();
    this.lastFetched = null;
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage.bind(this), false);
    const { onTwinLoading } = this.props;
    if (onTwinLoading) {
      onTwinLoading(0);
    }
    if ('Notification' in window && !this.isMobile) {
      Notification.requestPermission();
    }
  }

  componentDidUpdate(prevProps) {
    const { twinData, objectKey, loadModels, onTwinLoading } = this.props;
    if (!isEqual(prevProps.twinData.indexedByLevel, twinData.indexedByLevel)) {
      this.postMessageWithData(twinData, objectKey);
    }

    if ((loadModels.toString() !== prevProps.loadModels.toString()) && onTwinLoading) {
      onTwinLoading(0);
    }

    if (
      this.props.requestModelMapping
      && (this.props.requestModelMapping !== prevProps.requestModelMapping)
    ) {
      this.postMessage([
        'mapModel', [
          objectKey,
          this.props.requestModelMapping,
        ],
      ]);
    }

    if (this.props.mappingOverride && (this.props.mappingOverride !== prevProps.mappingOverride)) {
      this.postMessage([
        'overrideMapping', [
          objectKey,
          this.props.mappingOverride,
        ],
      ]);
      setTimeout(() => this.postMessageWithData(twinData, objectKey), 2000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage.bind(this), false);
  }

  viewerRef: React.RefObject<HTMLIFrameElement>;

  lastFetched: Date | null;

  isMobile: boolean;

  postMessage = (message) => {
    this.viewerRef?.current?.contentWindow?.postMessage(message, '*');
  };

  setShowModal = (e) => {
    if (!e) {
      this.setState({ assetTwinDataFromForge: [] });
      this.postMessage(['clearSelection']);
    }
    this.setState({ showModal: e });
  };

  postMessageWithData = (twinData: TwinData, objectKey: string) => {
    const { modelCode, config } = this.props;
    this.postMessage(['setColorConfig', config]);
    this.postMessage(['clearColor', objectKey]);
    this.postMessage(['updateData', [twinData, modelCode, objectKey]]);
    this.postMessage(['requestFitToView']);
  };

  getLoadingPercentage = () => {
    const { modelByKeys } = this.state;
    const { loadModels } = this.props;
    return calculateLoadingPercentage(modelByKeys, loadModels);
  };

  onMessage = (event) => {
    const functionName = event.data[0];
    const { onTwinReady, onTwinLoading } = this.props;
    const { modelByKeys } = this.state;


    if (functionName === 'onTwinProgress') {
      const { data } = event;
      const id = data[1];
      const percentage = data[2];
      const newModelByKeys = {
        ...modelByKeys,
        [id]: {
          id,
          loading: percentage,
        },
      };
      this.setState({
        modelByKeys: newModelByKeys,
      }, () => {
        if (onTwinLoading) {
          onTwinLoading(this.getLoadingPercentage());
        }
      });

    }
    if (functionName === 'onTwinReady' && onTwinReady) {
      onTwinReady(true, event?.data?.[1]);

      if (onTwinLoading) {
        onTwinLoading(100);
      }

      if (!document.hasFocus() && !this.isMobile && ('Notification' in window)) {
        new Notification('Digital twins is ready for viewing', {
          icon: '/src/favicon.png',
        });
      }

      notification.success({
        key: 'digital_twin_success',
        message: 'Digital twin is ready for viewing',
      });
    }

    if (functionName === 'onMapProgress') {
      this.props.onMapProgress?.(event.data[1]);
    }

    if (functionName === 'onMapComplete') {
      this.props.onMapComplete?.(event.data[1]);
    }

    if (this.registeredFunctions[functionName]) {
      const args = event.data.slice(1);
      this.registeredFunctions[functionName](...(args || {}));
    }
  };

  registeredFunctions = {
    getData: () => {
      const { twinData, objectKey } = this.props;
      this.postMessageWithData(twinData, objectKey);
    },
    getAssetSelected: (assetsSelected) => {
      //Temporay fix: getAssetSelected will be trigger at the first loading on twinUI
      this.setState({ showModal: false });

      const assetTwinDataFromForge = JSON.parse(assetsSelected);
      if (assetTwinDataFromForge.length) {
        this.setState({
          assetTwinDataFromForge: assetTwinDataFromForge,
          showModal: true,
        });
      }
    },
  };

  updateAssetTwinList;

  render() {
    const { source, project, tenant, loadModels, isTwinLoading } = this.props;
    const { assetTwinDataFromForge, showModal } = this.state;
    const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

    return (
      <>
        <TwinsTable
          assetTwinData={assetTwinDataFromForge}
          showModal={showModal}
          setShowModal={(e: boolean) => this.setShowModal(e)}
        />
        {isTwinLoading &&
          <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
              <div>Digital Twin Loading...</div>
              <div>We will notify you when ready</div>
            </div>
            <div style={{ width: 90, height: 90 }}>
              <CircularProgressbar
                value={this.getLoadingPercentage()}
                text={`${this.getLoadingPercentage()}%`}
              />
            </div>
            <Button
              style={{ marginTop: 50 }}
              onClick={this.props.onTwinCancel}
            >
              Cancel
            </Button>
          </div>
        }
        <iframe
          ref={this.viewerRef}
          // eslint-disable-next-line max-len
          src={`${source}/?accessToken=${accessToken}&project=${project}&tenant=${tenant}&loadModels=${loadModels}`}
          allowFullScreen={true}
          frameBorder="0"
          title="Twin"
          style={{
            minWidth: '100%',
            width: '100%',
            height: '100%',
            flex: 1,
            margin: '0 auto',
            position: 'absolute',
            visibility: isTwinLoading ? 'hidden' : 'visible',
          }}
          onLoad={() => {
            this.postMessage(['updateToolbar', '']);
          }}
        />
      </>
    );
  }
}
